let videoDurationIndex: { [key: string]: number };

function basename(str: string, sep = "/") {
  return str.substr(str.lastIndexOf(sep) + 1);
}

async function getVideoDuratinoIndex() {
  if (videoDurationIndex) return videoDurationIndex;
  else {
    videoDurationIndex = await (
      await fetch("./test-videos/duration_index.json")
    ).json();
    for (const key of Object.keys(videoDurationIndex)) {
      const noExtensionKey = key.slice(0, key.lastIndexOf("."));
      videoDurationIndex[noExtensionKey] = videoDurationIndex[key];
    }
    return videoDurationIndex;
  }
}

// Download immediately
getVideoDuratinoIndex();

export async function videoDuration(filename: string): Promise<number> {
  return (await getVideoDuratinoIndex())[filename];
}

export async function playlistDurations(playlist: string[]): Promise<number[]> {
  const durationIndex = await getVideoDuratinoIndex();
  return playlist.map((filename) => durationIndex[basename(filename)]);
}

export async function playlistStartTimes(
  syncStartTime: number,
  playlist: string[]
) {
  let t = syncStartTime;
  let startTimes = [];
  let durations = await playlistDurations(playlist);
  for (let i in playlist) {
    startTimes.push(t);
    t += durations[i];
  }
  return startTimes;
}

export async function addPlaylistStartTimes(
  syncStartTime: number,
  playlist: string[]
) {
  const startTimes = await playlistStartTimes(syncStartTime, playlist);
  return playlist.map((src, i) => ({ src, startTime: startTimes[i] }));
}

export async function syncPlaylist(syncStartTime: number, playlist: string[]) {
  const startTimes = await playlistStartTimes(syncStartTime, playlist);
  const now = Date.now() / 1000;
  const skipToIndex = startTimes.findIndex((t) => t > now);
  if (skipToIndex == -1) return [];
  else {
    const predelay = startTimes[skipToIndex] - now;
    return [predelay, ...playlist.slice(skipToIndex)];
  }
}

export async function videoExists(name: string): Promise<boolean> {
  return Boolean((await getVideoDuratinoIndex())[name]);
}
