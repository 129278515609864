import { useEffect, useMemo, useRef, useState } from "react";
import { NyeServerSimulation } from "./NyeServer";
import nyeRawScore from "./ContagionV1.json";
import { AutoplayVideo, FullScreenVideo } from "./VideoPlaylist";
import { videoExists } from "./video-durations";
import { ClickToBeginButton } from "./ClickToBegin";
import styled from "styled-components";

const Blackout = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
`;

const now = () => Date.now() / 1000;

class NyeClient {
  async getTimestamps() {
    const response = await fetch("nye-timestamps.php");
    const body = await response.json();
    return body.timestamps;
  }

  async postTimestamp(
    new_timestamp: number = now(),
    existing_timestamps = []
  ): Promise<{ new_timestamp: number; existing_timestamps: number[] }> {
    const requestBody = new FormData();
    requestBody.append(
      "data",
      JSON.stringify({ new_timestamp, existing_timestamps })
    );
    const response = await fetch("nye-timestamps.php", {
      method: "POST",
      body: requestBody,
    });
    const body = await response.json();

    if (body.instruction === "try_again")
      return this.postTimestamp(now(), body.timestamps);
    else if (body.success) return { new_timestamp, existing_timestamps };
    else throw new Error("something unexpected went wrong");
  }
}

const useNyeServer = (refreshInterval = 1000) => {
  const [timestamps, setTimestamps] = useState([]);

  useEffect(() => {
    let timer: any;
    const refreshTimestamps = async () => {
      const fetched_timestamps = await new NyeClient().getTimestamps();
      if (JSON.stringify(fetched_timestamps) !== JSON.stringify(timestamps))
        setTimestamps(fetched_timestamps);
      timer = setTimeout(refreshTimestamps, refreshInterval);
    };
    refreshTimestamps();
    return () => clearTimeout(timer);
  }, [timestamps]);

  const pushTimestamp = (timestamp: number) =>
    new NyeClient().postTimestamp(timestamp, timestamps);

  const nextVideo = async () => {
    const { existing_timestamps, new_timestamp } =
      await new NyeClient().postTimestamp(now(), timestamps);
    const firstTimestamp = existing_timestamps[0];

    const sync_start_time = existing_timestamps[0] || new_timestamp;
    const video = await NyeServerSimulation.nextVideo(
      nyeRawScore as any,
      { time: new_timestamp - sync_start_time },
      existing_timestamps.map((time) => ({ time: time - sync_start_time })),
      String(firstTimestamp || Date.now() / 1000)
    );
    console.log("nextVideo -> ", video);
    return video;
  };

  return {
    timestamps,
    nextVideo,
  };
};

export const NyePiece = () => {
  const { timestamps, nextVideo } = useNyeServer();

  const [video, setVideo] = useState(null as string | null);

  const count = useRef(0);

  const requestNextVideo = async () => {
    const video = await nextVideo();

    count.current++;
    if (video === null || !(await videoExists(video))) {
      console.log("video is ", video, " retrying the request in 500ms");
      setVideo("wait");
      setTimeout(() => requestNextVideo(), 500);
    } else {
      setVideo(video);
    }
  };

  if (!video)
    return (
      <ClickToBeginButton onClick={() => requestNextVideo()}>
        Tap here to begin...
      </ClickToBeginButton>
    );
  else if (video === "wait") return <p>Please wait</p>;
  // TODO: Bug where same video is played consecutively
  else {
    console.log("key", video + count.current);
    return (
      <Blackout>
        <AutoplayVideo
          key={video + count.current}
          src={`test-videos/${video}.mp4`}
          onEnded={() => requestNextVideo()}
        />
      </Blackout>
    );
  }
};
