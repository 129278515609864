import { FunctionComponent, ReactElement, ReactNode, useState } from "react";
import styled from "styled-components";

export const ClickToBeginButton = styled.button`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  background-color: transparent;
  background: none;
  color: inherit;
  border: none;
  font-size: 30px;
  font-family: inherit;
`;

export const ClickToBegin: FunctionComponent<{ children: ReactElement }> = ({
  children,
}) => {
  const [clicked, setClicked] = useState(false);
  if (clicked) return children;
  else
    return (
      <ClickToBeginButton onClick={() => setClicked(true)}>
        Tap here to begin
      </ClickToBeginButton>
    );
};
