import { FunctionComponent } from "react";
import styled from "styled-components";

interface MultipleChoiceProps {
  options: string[];
  question?: string;
  onAnswer?: (details: { answer: string; answerIndex: number }) => void;
}

export const MultipleChoiceDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  min-height: 100%;
  h2 {
    margin: 10px;
    text-align: center;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 0px;
    li {
      position: relative;
      overflow: hidden;
      flex-grow: 1;
      margin: 10px;
      padding: 0px;
      display: block;
      border: 1px solid #ccff40;
      margin: 20px;
      box-shadow: 1px 1px 2px #2600ad;
      backdrop-filter: blur(10px);
      animation: arrive 1s;
      button {
        min-height: 80px;
        width: 100%;
        height: 100%;
        border: none;
        font-weight: bold;
        font-size: 2em;
        background: none;
        color: inherit;
        text-shadow: 1px 1px 1px #2600ad;
        font-family: inherit;
      }
      video {
        position: absolute;
        left: 0px;
        width: 100%;
        z-index: -1;
        animation: fadein 10s;
        filter: blur(10px);
      }
    }
  }

  @keyframes arrive {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MultipleChoice: FunctionComponent<MultipleChoiceProps> = ({
  question,
  options,
  onAnswer,
}) => {
  const handleAnswer = (index: number) => () => {
    if (onAnswer) onAnswer({ answer: options[index], answerIndex: index });
  };

  return (
    <MultipleChoiceDiv>
      {question && <h2>{question}</h2>}
      <ul className="MultipleChoiceOptions">
        {options.map((option, i) => (
          <li key={option}>
            <button onClick={handleAnswer(i)}>{option}</button>
          </li>
        ))}
      </ul>
    </MultipleChoiceDiv>
  );
};
