const createUndertone = () => {
  if (!audioCtx) throw new Error("Audio context missing");
  const osc = audioCtx.createOscillator();
  osc.frequency.value = 100;
  osc.start();
  const gain = audioCtx.createGain();
  gain.gain.value = 0.0001;
  osc.connect(gain);
  return gain;
};

export let audioCtx: AudioContext;
const handleFirstClick = () => {
  audioCtx = new AudioContext();
  const undertone = createUndertone();
  undertone.connect(audioCtx.destination);
  console.log("Created audio context: ", audioCtx);
  window.removeEventListener("mousedown", handleFirstClick);
};
window.addEventListener("mousedown", handleFirstClick);

export function loadAudioBuffer(
  url: string,
  ctx: AudioContext
): Promise<AudioBuffer> {
  return new Promise((fulfil, reject) => {
    const xhttp = new XMLHttpRequest();
    xhttp.open("get", url);
    xhttp.responseType = "arraybuffer";
    xhttp.onload = () => {
      let arrayBuffer = xhttp.response;
      ctx.decodeAudioData(
        arrayBuffer,
        (buffer) => {
          fulfil(buffer);
        },
        (err) => {
          reject(err);
        }
      );
    };
    xhttp.onerror = (err) => {
      reject(err);
    };
    xhttp.send();
  });
}
