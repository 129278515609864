import { FunctionComponent, useState } from "react";
import { FinalScreen } from "./FinalScreen";
import { MultipleChoice, MultipleChoiceDiv } from "./MultipleChoice";

import { VideoPlaylist } from "./VideoPlaylist";
import { addPlaylistStartTimes, syncPlaylist } from "./video-durations";

const shuffle = (items: string[]) => items.sort(() => 2 * Math.random() - 1);

const playlists: { [instrument: string]: string[] } = {
  cello: [
    "test-videos/Cello-A.mp4",
    "test-videos/Cello-B.mp4",
    "test-videos/Cello-C.mp4",
  ],
  clarinet: [
    "test-videos/Clarinet-A.mp4",
    "test-videos/Clarinet-B.mp4",
    "test-videos/Clarinet-C.mp4",
  ],
  flute: [
    "test-videos/Flute-A.mp4",
    "test-videos/Flute-B.mp4",
    "test-videos/Flute-C.mp4",
  ],
  piano: [
    "test-videos/Piano-A.mp4",
    "test-videos/Piano-B.mp4",
    "test-videos/Piano-C.mp4",
  ],
};

async function getSyncingInformation(): Promise<{ sync_start_time: number }> {
  const response = await fetch("./amelia-sync.php");
  const body = await response.json();
  return body;
}

export const AmeliaPiece: FunctionComponent = () => {
  const [chosenInstrument, setChosenInstrument] = useState(
    null as string | null
  );
  const [playlist, setPlaylist] = useState(
    null as null | { src: string; startTime: number }[]
  );

  const handleInstrumentChoice = async (answer: string) => {
    setChosenInstrument(answer);
    const syncingInformation = await getSyncingInformation();
    const syncStartTime = syncingInformation.sync_start_time;
    const playlist = shuffle(playlists[answer.toLowerCase()]);
    const syncedPlaylist = await addPlaylistStartTimes(syncStartTime, playlist);
    console.log("Synced playlist", syncedPlaylist);
    setPlaylist(syncedPlaylist);
  };

  const [pieceFinished, setPieceFinished] = useState(false);
  const handlePieceFinished = () => {
    setPlaylist(null);
    setPieceFinished(true);
  };

  if (chosenInstrument == null)
    return (
      <MultipleChoiceDiv>
        <h2>Please choose an instrument:</h2>
        <ul className="MultipleChoiceOptions">
          <li>
            <button onClick={() => handleInstrumentChoice("Cello")}>
              Cello
            </button>
            <video
              muted
              loop
              autoPlay
              playsInline
              className="button-background"
              src={"test-videos/Cello-A.mp4"}
            />
          </li>

          <li>
            <button onClick={() => handleInstrumentChoice("Clarinet")}>
              Clarinet
            </button>
            <video
              muted
              autoPlay
              loop
              playsInline
              className="button-background"
              src={"test-videos/Clarinet-A.mp4"}
            />
          </li>
          <li>
            <button onClick={() => handleInstrumentChoice("Flute")}>
              Flute
            </button>
            <video
              muted
              autoPlay
              playsInline
              loop
              className="button-background"
              src={"test-videos/Flute-A.mp4"}
            />
          </li>
          <li>
            <button onClick={() => handleInstrumentChoice("Piano")}>
              Piano
            </button>
            <video
              muted
              autoPlay
              playsInline
              loop
              className="button-background"
              src={"test-videos/Piano-A.mp4"}
            />
          </li>
        </ul>
      </MultipleChoiceDiv>
    );
  else if (playlist) {
    return <VideoPlaylist videos={playlist} onEnded={handlePieceFinished} />;
  } else if (pieceFinished) return <FinalScreen />;
  // TODO: Error page
  else return null;
};
