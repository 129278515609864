import Rand from "rand-seed";
import { videoDuration } from "./video-durations";

/**
 * This interface describes the shape of Nye's JSON scores as they are recieved
 * from the composer.
 */
export interface RawNyeScore {
  [timestamp_in_seconds: number]:
    | {
        transition: number;
        data: string[];
        eventtype: "Pool";
      }
    | {
        eventtype: "Maximum";
        data: number;
        transition: number;
      }
    | {
        transition: number;
        /**
         * The filename of the video
         */
        data: string | string[];
        eventtype: "Guarantee";
      }
    | {
        transition: number;
        data: "";
        eventtype: "End";
      };
}

interface PoolEvent {
  time: number;
  kind: "pool";
  videos: string[];
  transition: number;
}

interface MaximumEvent {
  time: number;
  kind: "maximum";
  maximumNumberOfVideos: number;
  transition: number;
}

interface GuaranteeEvent {
  time: number;
  kind: "guarantee";
  video: string[];
}

interface EndEvent {
  time: number;
  kind: "end";
}

class EventSequence<Event extends { time: number }> {
  protected events: Event[];
  constructor() {
    this.events = [];
  }

  private sortEvents() {
    this.events.sort((a, b) => a.time - b.time);
  }

  public addEvent(event: Event) {
    this.events.push(event);
    this.sortEvents();
  }

  public addEvents(...events: Event[]) {
    this.events.push(...events);
    this.sortEvents();
  }

  public eventByIndex(index: number) {
    return this.events[index];
  }
}

class NyeScore extends EventSequence<
  PoolEvent | MaximumEvent | GuaranteeEvent | EndEvent
> {
  constructor(rawScore: RawNyeScore) {
    super();
    this.addEvents(...NyeScore.parseRawScore(rawScore));
    console.log(this.events);
  }

  static parseRawScore(rawScore: RawNyeScore): ScoreEvent[] {
    return NyeScore.extractKeysAsTimeStamps(rawScore).map((event) =>
      NyeScore.normaliseNyeEvent(event)
    );
  }

  static extractKeysAsTimeStamps<T>(score: {
    [key: number]: T;
  }): (T & { time: number })[] {
    return Object.keys(score).map((key) => ({
      ...score[key as any as number],
      time: Number(key),
    }));
  }

  static normaliseNyeEvent({
    time,
    eventtype,
    transition,
    data,
  }: RawNyeScore[number] & { time: number }): ScoreEvent {
    function normaliseVideoName(name: string) {
      return name.slice(0, name.lastIndexOf("."));
    }
    switch (eventtype) {
      case "End":
        return {
          time,
          kind: "end",
        };
      case "Guarantee":
        return {
          time,
          kind: "guarantee",
          video:
            data instanceof Array
              ? data.map((video) => normaliseVideoName(video))
              : [normaliseVideoName(data)],
        };
      case "Maximum":
        return {
          time,
          kind: "maximum",
          maximumNumberOfVideos: data,
          transition,
        };
      case "Pool":
        return {
          time,
          kind: "pool",
          videos: data.map((video) => normaliseVideoName(video)),
          transition,
        };
      default:
        throw new Error("Unexpected eventtype in Nye's score: " + eventtype);
    }
  }
}

type ScoreEvent = NyeScore["events"][number];

interface NextVideoRequest {
  time: number;
}

/**
 * Random number generator seed used during tests.
 */
export const testSeed = "A";

/**
 * Not a real server. This class simulates the behaviour server described in
 * Nye's tech score.
 */
export class NyeServer {
  private random: () => number;
  private poolServer: PoolServer;
  private guaranteeServer: GauranteeServer;
  private maximumServer: MaximumServer;
  private ended: boolean;

  constructor(seed = testSeed) {
    const rand = new Rand(seed);
    this.random = () => rand.next();
    this.poolServer = new PoolServer(this.random);
    this.guaranteeServer = new GauranteeServer();
    this.maximumServer = new MaximumServer();
    this.ended = false;
  }

  public async handleRequest(
    request: NextVideoRequest
  ): Promise<
    | { video: string }
    | { video: null; reason: "maximum exceeded"; callAgainAfter: number }
    | { video: null; reason: "piece ended" }
  > {
    console.log("handling request:", request);
    if (this.ended) return { video: null, reason: "piece ended" };
    if (this.maximumServer.maximumIsExceeded(request)) {
      console.log(this.maximumServer);
      return { video: null, reason: "maximum exceeded", callAgainAfter: 2000 };
    }
    const video =
      this.guaranteeServer.nextVideo() ||
      this.poolServer.nextVideo(request.time);
    if (video) await this.maximumServer.handleVideoServed(request.time, video);
    return { video };
  }

  public handleScoreEvent(event: ScoreEvent): void {
    console.log("handling " + event.kind.toUpperCase() + " event:", event);
    switch (event.kind) {
      case "pool":
        this.poolServer.handlePoolEvent(event);
        return;
      case "maximum":
        this.maximumServer.handleMaximumEvent(event);
        return;
      case "guarantee":
        this.guaranteeServer.handleGuaranteeEvent(event);
        return;
      case "end":
        this.ended = true;
        return;
    }
  }
}

class MaximumServer {
  private transitionTimer: TransitionTimer;
  private playingVideos: { video: string; endTime: number }[];
  private targetMaximum?: number;
  private previousMaximum?: number;

  constructor() {
    this.transitionTimer = new TransitionTimer();
    this.playingVideos = [];
  }

  /**
   * From tech score:
   * ```markdown
   *   The target maximum (highest number of videos to be played) is set. The
   *   transition field gives the time over which the maximum is ramped up or
   *   down to this target value.
   * ```
   */
  public handleMaximumEvent(event: MaximumEvent) {
    if (this.targetMaximum !== undefined)
      this.previousMaximum = this.targetMaximum;
    else this.previousMaximum = event.maximumNumberOfVideos;
    this.targetMaximum = event.maximumNumberOfVideos;
    this.transitionTimer.set(event.time, event.time + event.transition);
  }

  public async handleVideoServed(time: number, video: string) {
    const duration = await videoDuration(video);
    if (!duration) throw new Error("Couldn't find duration for " + video);
    this.playingVideos.push({
      video,
      endTime: time + duration,
    });
  }

  private maximumIsDefined() {
    return this.targetMaximum !== undefined;
  }

  private currentMaximum(time: number): number {
    if (this.transitionTimer.inTransition(time)) {
      const progress = this.transitionTimer.progress(time);
      return (
        progress * (this.targetMaximum as number) +
        (1 - progress) * (this.previousMaximum as number)
      );
    } else return this.targetMaximum as number;
  }

  private countPlayingVideos(time: number) {
    return this.playingVideos.filter((record) => record.endTime < time).length;
  }

  public maximumIsExceeded(request: NextVideoRequest): boolean {
    return (
      this.maximumIsDefined() &&
      this.countPlayingVideos(request.time) < this.currentMaximum(request.time)
    );
  }
}

class TransitionTimer {
  private transitionStart?: number;
  private transitionEnd?: number;

  public inTransition(time: number) {
    if (this.transitionStart === undefined || this.transitionEnd === undefined)
      return false;
    return this.transitionStart < time && this.transitionEnd > time;
  }

  public progress(time: number) {
    if (
      this.inTransition(time) &&
      this.transitionEnd !== undefined &&
      this.transitionStart !== undefined
    )
      return (
        (time - this.transitionStart) /
        (this.transitionEnd - this.transitionStart)
      );
    else return 1.0;
  }

  public set(transitionStart: number, transitionEnd: number) {
    this.transitionStart = transitionStart;
    this.transitionEnd = transitionEnd;
  }
}

class PoolServer {
  private previousVideoPool: string[];
  private currentVideoPool: string[];
  private random: () => number;
  private transitionTimer: TransitionTimer;

  constructor(randomNumberGenerator: () => number) {
    this.previousVideoPool = [];
    this.currentVideoPool = [];
    this.random = randomNumberGenerator;
    this.transitionTimer = new TransitionTimer();
  }
  /**
   * from tech score:
   *
   * ```markdown
   *   The `currentList` array is copied into the `previousList` array and the data
   *   field (list of filenames) is copied into the currentList array. The
   *   transition field is checked. If > 0 the transition time is set. This is used
   *   to control the probability balance between selecting files from the (newly
   *   set) previous list (100-0%and the (new) current list (0-100%)
   * ```
   * */
  public handlePoolEvent(event: PoolEvent): void {
    this.previousVideoPool = this.currentVideoPool;
    this.currentVideoPool = event.videos;
    this.transitionTimer = new TransitionTimer();
  }

  public nextVideo(time: number): string {
    // TODO: Make time a stateful member not an argument
    if (this.transitionTimer.inTransition(time))
      return this.selectVideoFromTransitionPool(time);
    else return this.selectVideoFromCurrentPool();
  }

  private selectVideoFromTransitionPool(time: number): string {
    const probabilityOfCurrentPool = this.transitionTimer.progress(time);
    if (this.random() < probabilityOfCurrentPool)
      return this.selectVideoFromCurrentPool();
    else return this.selectVideoFromPreviousPool();
  }

  private selectVideoFromPool(pool: string[]) {
    if (pool) return pool[Math.floor(this.random() * pool.length)];
    else throw new Error("Attempt to select from pool before it is populated");
  }

  private selectVideoFromCurrentPool() {
    return this.selectVideoFromPool(this.currentVideoPool);
  }

  private selectVideoFromPreviousPool() {
    return this.selectVideoFromPool(this.previousVideoPool);
  }
}

class GauranteeServer {
  private queue: string[];

  constructor() {
    this.queue = [];
  }

  /**
   * from tech score:
   *
   * ```markdown
   *   The guaranteeQueue is filled with data from the data field.
   * ```
   */
  public handleGuaranteeEvent(event: GuaranteeEvent): void {
    this.queue = [...this.queue, ...event.video];
  }

  public nextVideo() {
    return this.queue.shift() || null;
  }
}

export class NyeServerSimulation {
  private score: NyeScore;
  private server: NyeServer;

  private constructor(score: RawNyeScore, seed = testSeed) {
    this.score = new NyeScore(score);
    this.server = new NyeServer(seed);
    this.lastScoreEventIndex = -1;
  }

  /**
   * Index (within the score) of last score event to have been simulated.
   */
  private lastScoreEventIndex: number;
  private nextScoreEvent(): ScoreEvent {
    return this.score.eventByIndex(this.lastScoreEventIndex + 1);
  }

  private simulateNextScoreEvent() {
    const event = this.nextScoreEvent();
    this.server.handleScoreEvent(event);
    ++this.lastScoreEventIndex;
  }

  private simulateElapsedScoreEvents(untilTime: number): void {
    while (this.nextScoreEvent() && this.nextScoreEvent().time <= untilTime) {
      this.simulateNextScoreEvent();
    }
  }

  private async simulateRequest(
    request: NextVideoRequest
  ): Promise<{ video: string | null }> {
    this.simulateElapsedScoreEvents(request.time);
    return await this.server.handleRequest(request);
  }
  private async simulateRequests(
    ...requests: NextVideoRequest[]
  ): Promise<void> {
    for (const request of requests) await this.simulateRequest(request);
  }

  /**
   * Entry point used by the app.
   *
   * Statelessly fetch or predict next video based on a request, a score, a
   * list of recent requests and a seed.
   */
  public static async nextVideo(
    score: RawNyeScore,
    newRequest: NextVideoRequest,
    oldRequests: NextVideoRequest[],
    seed = testSeed
  ): Promise<string | null> {
    const server = new NyeServerSimulation(score, seed);
    await server.simulateRequests(...oldRequests);
    const response = await server.simulateRequest(newRequest);
    return response.video;
  }
}
