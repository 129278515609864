import "./App.css";
import ReactPlayer from "react-player";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { AmeliaPiece } from "./AmeliaPiece";
import { MultipleChoice } from "./MultipleChoice";
import { MikeyPiece } from "./MikeyPiece";
import { NyePiece } from "./NyePiece";
import { JoelleTechnicalTest } from "./JoelleTechnicalTest";

import bg from "./background/terra invisus backgroun_background dark 1-06.png";
import { FinalScreen } from "./FinalScreen";

const BackgroundImage = styled.img`
  position: fixed;
  width: 100vw;
  bottom: 0px;
  height: 100vh;
  z-index: -10;
  left: 0px;
  right: 0px;
`;

function App() {
  const [composer, setComposer] = useState(
    window.location.hash?.slice(1) || null
  );

  if (!composer)
    return (
      <MultipleChoice
        options={["Amelia", "Mikey", "Nye", "Technical Test", "Credits"]}
        onAnswer={({ answer }) => {
          setComposer(answer);
        }}
      />
    );
  else if (composer == "Amelia") return <AmeliaPiece />;
  else if (composer == "Mikey") return <MikeyPiece />;
  else if (composer == "Nye") return <NyePiece />;
  else if (composer == "Technical Test") return <JoelleTechnicalTest />;
  else if (composer == "Credits") return <FinalScreen />;
  else return <p>Unexpected composer: {composer}</p>;
}

const AppWithBackground: FunctionComponent = () => (
  <div>
    <App></App>
    <BackgroundImage src={bg} />
  </div>
);

export default AppWithBackground;
