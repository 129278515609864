import { useEffect, useMemo, useState } from "react";
import { audioCtx, loadAudioBuffer } from "./globalAudioContext";

/*
 * Returns `null if still loading
 */
export function useAudioBuffer(src: string): AudioBuffer | null {
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  useEffect(() => {
    loadAudioBuffer(src, audioCtx).then((buffer) => {
      setAudioBuffer(buffer);
    });
  }, [src]);
  return audioBuffer;
}

export function useAudioSource(src: string): AudioBufferSourceNode | null {
  const buffer = useAudioBuffer(src);
  if (buffer) {
    const source = audioCtx.createBufferSource();
    source.buffer = buffer;
    source.connect(audioCtx.destination);
    return source;
  } else return null;
}

export function useScheduledAudio(
  src: string,
  startTimestamp: number = 0,
  loop = false
) {
  const buffer = useAudioBuffer(src);

  useEffect(() => {
    if (buffer) {
      const source = audioCtx.createBufferSource();
      source.buffer = buffer;
      source.connect(audioCtx.destination);
      source.loop = true;

      if (startTimestamp === undefined) {
        source.start();
      } else {
        const delay = startTimestamp - Date.now() / 1000;
        if (delay > 0)
          source.start(
            audioCtx.currentTime - Date.now() / 1000 + startTimestamp
          );
        else source.start(0, -delay);
      }

      return () => {
        source.stop();
      };
    }
  }, [buffer]);

  return !!buffer;
}
