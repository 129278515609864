import { FunctionComponent } from "react";
import styled from "styled-components";
import FentonArts from "./Fenton logo hi res.jpeg";
import LondonSinfonietta from "./London Sinfonietta logo white.png";
import terrus from "./SVG_Logos/terra invisus logo_terra invisus logo neon.svg";

const FinalScreenDiv = styled.div`
  justify-content: space-around;
  margin: 20px;

  position: absolute;
  top: 0px;
  bottom: 0px;
`;

const Logos = styled.div`
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;
`;

const Logo = styled.img`
  width: 40%;
  margin: 20px;
  display: block;
  margin-right: auto;
  margin-left: auto;
`;

export const FinalScreen: FunctionComponent = () => {
  return (
    <FinalScreenDiv>
      <h2>Thanks for listening.</h2>
      <p>
        This web app is part of{" "}
        <a href="http://www.londonsinfonietta.org.uk">London Sinfonietta’s</a>{" "}
        digital commissioning Blue Touch Paper programme
      </p>
      <p>
        Supported by{" "}
        <a href="https://www.fentonartstrust.org.uk">Fenton Arts Trust</a>.
      </p>

      <p>
        The music was performed by{" "}
        <a href="https://mobile.twitter.com/terrainvisus">Terra Invisus</a> trio
        with flautist Daniel Shao.
      </p>

      <h3>The pieces:</h3>

      <p>
        <i>Contagion</i> by <a href="https://www.nyeparry.com">Nye Parry</a>
      </p>

      <p>
        <i>Corners</i> by{" "}
        <a href="https://www.ameliaclarkson.com/">Amelia Clarkson</a>
        <br />
        Choreographer: Ruaidhrí Maguire
        <br />
        Dancers: Mayu Takata, Milena Crameri, Rui Nagahori & Ruaidhrí Maguire
        <br />
        Video: Amelia Clarkson
      </p>

      <p>
        Music by <a href="https://www.mikeyparsons.com/">Mikey Parsons</a> and{" "}
        <a href="https://www.owlstation.com/">Alice Parsons</a>
      </p>

      <p className="credit">
        Programming by <a href="http://joelleplow.co.uk/">Joelle Plow</a>
      </p>

      <Logos>
        <a href="">
          <Logo src={terrus} />
        </a>
        <a href="http://www.londonsinfonietta.org.uk">
          <Logo src={LondonSinfonietta} />
        </a>
        <a href="https://www.fentonartstrust.org.uk">
          <Logo
            src={FentonArts}
            // style={{ mixBlendMode: "multiply", filter: "brightness(100)" }}
          />
        </a>
      </Logos>
    </FinalScreenDiv>
  );
};
