import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ClickToBegin } from "./ClickToBegin";

import bassline from "./mikystems/bassline.wav";
import chordz from "./mikystems/chordz.wav";
import clickclap from "./mikystems/clickclap.wav";
import hats from "./mikystems/hats.wav";
import kick from "./mikystems/kick.wav";
import melody from "./mikystems/melody.wav";
import { MultipleChoice } from "./MultipleChoice";
import { ScheduledAudio } from "./VideoPlaylist";

const stemz = [bassline, chordz, clickclap, hats, kick, melody];
const stemzLabelled = { bassline, chordz, clickclap, hats, kick, melody };
const interval = 8;
const randomStem = () => stemz[Math.floor(stemz.length * Math.random())];
const nextStartTime = () => Math.floor(Date.now() / 1000 / interval) * interval;

export const JoelleTechnicalTest: FunctionComponent = () => {
  const [state, setState] = useState({
    stem: randomStem(),
    startTime: nextStartTime(),
  });

  // const [started, setStarted] = useState(false);
  // if (!started)
  //   return (
  //     <button onClick={() => setStarted(true)} style={{ fontSize: "30px" }}>
  //       START
  //     </button>
  //   );

  return (
    <ClickToBegin>
      <div>
        <ScheduledAudio
          loop
          key={JSON.stringify(state)}
          src={state.stem}
          startTime={state.startTime}
        />
        <MultipleChoice
          options={[
            "bassline",
            "chordz",
            "clickclap",
            "hats",
            "kick",
            "melody",
            "random",
          ]}
          onAnswer={({ answer }) =>
            setState({
              // @ts-ignore
              stem: answer == "random" ? randomStem() : stemzLabelled[answer],
              startTime: nextStartTime(),
            })
          }
        />
      </div>
    </ClickToBegin>
  );
};
