import { FunctionComponent, useEffect, useState } from "react";
import { ClickToBeginButton } from "./ClickToBegin";
import { FinalScreen } from "./FinalScreen";
import { MultipleChoice } from "./MultipleChoice";
import { AutoplayVideo } from "./VideoPlaylist";

interface MikeyScore {
  [key: string]:
    | {
        nodeKind: "video";
        video: string;
        then?: string;
      }
    | {
        nodeKind: "choice";
        question?: string;
        options:
          | string[]
          | {
              [answer: string]: string;
            };
      }
    | { nodeKind: "final" };
}

const score: MikeyScore = {
  cello: {
    nodeKind: "video",
    video: "./test-videos/video1.mp4",
    then: "choice1",
  },
  choice1: { nodeKind: "choice", options: ["video1a", "video1b"] },
  video1a: {
    nodeKind: "video",
    video: "./test-videos/video1a.mp4",
    then: "choice1a",
  },
  choice1a: { nodeKind: "choice", options: ["video1c", "video1d"] },
  video1b: {
    nodeKind: "video",
    video: "./test-videos/video1b.mp4",
    then: "choice1b",
  },
  choice1b: { nodeKind: "choice", options: ["video1e", "video1f"] },
  video1c: {
    nodeKind: "video",
    video: "./test-videos/video1c.mp4",
    then: "final1",
  },
  video1d: {
    nodeKind: "video",
    video: "./test-videos/video1d.mp4",
    then: "final1",
  },
  video1e: {
    nodeKind: "video",
    video: "./test-videos/video1e.mp4",
    then: "final1",
  },
  video1f: {
    nodeKind: "video",
    video: "./test-videos/video1f.mp4",
    then: "final1",
  },

  clarinet: {
    nodeKind: "video",
    video: "./test-videos/video2.mp4",
    then: "choice2",
  },
  choice2: { nodeKind: "choice", options: ["video2a", "video2b"] },
  video2a: {
    nodeKind: "video",
    video: "./test-videos/video2a.mp4",
    then: "choice2a",
  },
  choice2a: { nodeKind: "choice", options: ["video2c", "video2d"] },
  video2b: {
    nodeKind: "video",
    video: "./test-videos/video2b.mp4",
    then: "choice2b",
  },
  choice2b: { nodeKind: "choice", options: ["video2e", "video2f"] },
  video2c: {
    nodeKind: "video",
    video: "./test-videos/video2c.mp4",
    then: "final2",
  },
  video2d: {
    nodeKind: "video",
    video: "./test-videos/video2d.mp4",
    then: "final2",
  },
  video2e: {
    nodeKind: "video",
    video: "./test-videos/video2e.mp4",
    then: "final2",
  },
  video2f: {
    nodeKind: "video",
    video: "./test-videos/video2f.mp4",
    then: "final2",
  },

  flute: {
    nodeKind: "video",
    video: "./test-videos/video3.mp4",
    then: "choice3",
  },
  choice3: { nodeKind: "choice", options: ["video3a", "video3b"] },
  video3a: {
    nodeKind: "video",
    video: "./test-videos/video3a.mp4",
    then: "choice3a",
  },
  choice3a: { nodeKind: "choice", options: ["video3c", "video3d"] },
  video3b: {
    nodeKind: "video",
    video: "./test-videos/video3b.mp4",
    then: "choice3b",
  },
  choice3b: { nodeKind: "choice", options: ["video3e", "video3f"] },
  video3c: {
    nodeKind: "video",
    video: "./test-videos/video3c.mp4",
    then: "final3",
  },
  video3d: {
    nodeKind: "video",
    video: "./test-videos/video3d.mp4",
    then: "final3",
  },
  video3e: {
    nodeKind: "video",
    video: "./test-videos/video3e.mp4",
    then: "final3",
  },
  video3f: {
    nodeKind: "video",
    video: "./test-videos/video3f.mp4",
    then: "final3",
  },

  piano: {
    nodeKind: "video",
    video: "./test-videos/video4.mp4",
    then: "choice4",
  },
  choice4: { nodeKind: "choice", options: ["video4a", "video4b"] },
  video4a: {
    nodeKind: "video",
    video: "./test-videos/video4a.mp4",
    then: "choice4a",
  },
  choice4a: { nodeKind: "choice", options: ["video4c", "video4d"] },
  video4b: {
    nodeKind: "video",
    video: "./test-videos/video4b.mp4",
    then: "choice4b",
  },
  choice4b: { nodeKind: "choice", options: ["video4e", "video4f"] },
  video4c: {
    nodeKind: "video",
    video: "./test-videos/video4c.mp4",
    then: "final4",
  },
  video4d: {
    nodeKind: "video",
    video: "./test-videos/video4d.mp4",
    then: "final4",
  },
  video4e: {
    nodeKind: "video",
    video: "./test-videos/video4e.mp4",
    then: "final4",
  },
  video4f: {
    nodeKind: "video",
    video: "./test-videos/video4f.mp4",
    then: "final4",
  },

  final1: {
    nodeKind: "video",
    video: "./test-videos/video1end.mp4",
    then: "done",
  },
  final2: {
    nodeKind: "video",
    video: "./test-videos/video2end.mp4",
    then: "done",
  },
  final3: {
    nodeKind: "video",
    video: "./test-videos/video3end.mp4",
    then: "done",
  },
  final4: {
    nodeKind: "video",
    video: "./test-videos/video4end.mp4",
    then: "done",
  },
  done: { nodeKind: "final" },
};

function dictionarify(arr: string[]): { [key: string]: string } {
  let dictionary: { [key: string]: string } = {};
  for (const item of arr) dictionary[item] = item;
  return dictionary;
}

export const MikeyPiece: FunctionComponent = () => {
  const [place, setPlace] = useState(null as string | null);

  useEffect(() => {
    const fetchInstrument = async () => {
      console.log("Fetching instrument");
      const response = await fetch("./mikey-get-instrument.php");
      const body = await response.json();
      const instrument = body.instrument;
      setPlace(instrument);
    };

    fetchInstrument().catch(console.error);
  }, []);

  useEffect(() => {
    if (place && score[place].nodeKind === "choice") {
      const timer = setTimeout(() => {
        //@ts-ignore
        const options = Object.keys(score[place].options);
        const choice = options[Math.floor(Math.random() * options.length)];
        setPlace(choice);
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [place]);

  const [started, setStarted] = useState(false);
  if (!started)
    return (
      <ClickToBeginButton
        onClick={() => setStarted(true)}
        style={{ fontSize: "30px" }}
      >
        Tap here to begin...
      </ClickToBeginButton>
    );

  if (!place) {
    // TODO: Better waiting screen
    return <p>Please wait...</p>;
  }

  const currentNode = score[place];
  const nodeKind = currentNode.nodeKind;

  if (nodeKind === "choice") {
    const options =
      currentNode.options instanceof Array
        ? dictionarify(currentNode.options)
        : currentNode.options;
    return (
      <MultipleChoice
        question={currentNode.question}
        options={Object.keys(options)}
        onAnswer={({ answer }) => {
          const nextPlace = options[answer];
          setPlace(nextPlace);
        }}
      />
    );
  } else if (nodeKind === "video") {
    return (
      <AutoplayVideo
        src={currentNode.video}
        onEnded={() => {
          if (currentNode.then) setPlace(currentNode.then);
        }}
      />
    );
  } else if (nodeKind === "final") {
    return <FinalScreen />;
  } else {
    // TODO: Error page
    return null;
  }
};
