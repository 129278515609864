import { FunctionComponent, useEffect, useState } from "react";

export const Countdown: FunctionComponent<{ time: number }> = ({ time }) => {
  const [remaining, setRemaining] = useState(time - Date.now() / 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining(time - Date.now() / 1000);
    }, 25);
    return () => clearInterval(timer);
  }, []);

  return <span>{remaining.toFixed(2)}</span>;
};
