import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Countdown } from "./Countdown";
import { useScheduledAudio } from "./useAudio";
import { WebAudioPlayer } from "./WebAudioPlayer";

const HideyShoweyVideo = styled.video`
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: black;
  max-width: 100%;
  top: 0px;
  display: block;
  height: 100%;
  transition: opacity 1s;
  &.playing {
    opacity: 1;
  }
  &.paused {
    opacity: 0;
  }
`;

export const FullScreenVideo = styled.video`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  max-height: 100vh;
`;

export const VideoPlaylist: FunctionComponent<{
  videos: { src: string; startTime: number }[];
  onEnded?: () => void;
}> = ({ videos, onEnded }) => {
  // Find the last video:
  let last = videos[0];
  for (const video of videos)
    if (video.startTime > last.startTime) last = video;

  return (
    <div>
      {videos.map((video) => (
        <ScheduledVideo
          src={video.src}
          startTime={video.startTime}
          key={video.startTime}
          onEnded={video === last ? onEnded : undefined}
        />
      ))}
    </div>
  );
};

export const ScheduledAudio: FunctionComponent<{
  startTime: number;
  src: string;
  showCount?: boolean;
  loop?: boolean;
}> = ({ startTime, src, loop, showCount }) => {
  const buffer = useScheduledAudio(src, startTime, loop);
  if (showCount)
    return (
      <p>
        {src} at <Countdown time={startTime} />
        <br />
        {buffer ? "Audio is ready" : "NOT READY"}
      </p>
    );
  else return null;
};

export const ScheduledVideo: FunctionComponent<{
  startTime: number;
  src: string;
  onEnded?: () => void;
}> = ({ startTime, src, onEnded }) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);

  function startVideo() {
    setPlaying(true);
    ref.current?.play();
  }

  function startVideoAtTime(t: number) {
    setPlaying(true);
    if (ref.current) ref.current.currentTime = t;
    ref.current?.play();
  }

  useEffect(() => {
    const delay = startTime - Date.now() / 1000;
    if (delay > 0) {
      const timer = setTimeout(() => startVideo(), delay * 1000);
      return () => clearTimeout(timer);
    } else if (delay < 0) {
      startVideoAtTime(-delay);
    } else if (startTime === undefined) startVideo();
  }, [startTime]);

  return (
    <div>
      <ScheduledAudio {...{ startTime, src }} />
      <HideyShoweyVideo
        className={playing ? "playing" : "paused"}
        muted
        playsInline
        src={src}
        ref={ref}
        onEnded={() => {
          setPlaying(false);
          onEnded?.();
        }}
      ></HideyShoweyVideo>
    </div>
  );
};

export const AutoplayVideo: FunctionComponent<{
  src: string;
  onEnded?: () => void;
}> = ({ src, onEnded }) => {
  const startTime = useMemo(() => Date.now() / 1000 + 1, [src]);
  return <ScheduledVideo {...{ src, onEnded, startTime }} />;
};
